<template>
  <section id="schema" class="section_container">
    <v-container>
      <default-home-section-title :title="titleSection" />
      <v-img width="100%" class="mx-auto" :src="imgSchema"></v-img>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "HomeSectionSchema",
  components: {
    DefaultHomeSectionTitle: () => import("@/views/Home/HomeSectionTitle.vue"),
  },
  data() {
    return {
      titleSection: "Генеральная схема",
      // imgSchema: require("@/assets/img/public-schema-img-01.jpeg"),
      // imgSchema: require("@/assets/img/public-schema-img-02.png"),
      imgSchema: require("@/assets/img/public-schema-img-03.jpg"),
      newImgSchema: require("@/assets/img/public-schema-img-02.png"), //! По распоряжению Жанарыса от 31.03.2022
    };
  },
};
</script>
<style lang="scss" scoped>
.section_container {
  background-color: #fff;
  width: 70%;
  margin: 0 auto;
}
.section_link {
  color: white;
  text-decoration: none;
}
.section_link:hover {
  color: black;
}
</style>
